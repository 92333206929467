<template>
  <v-theme-provider dark>
    <base-section
      id="real-value"
      class="purple darken-4 pa-4"
      space="20"
    >
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="8"
            sm="10"
          >
            <base-body space="2">
              <h1 class="mb-3 white--text">
                Você deve ter se perguntando <u>quanto custa</u> esse acompanhamento, certo?
              </h1>
              <h1 class="mb-3 white--text">
                Mas a pergunta certa é:
                <v-chip
                  color="purple lighten-2"
                  dark
                >
                  Quanto vale?
                </v-chip>
              </h1>
              <div>
                <b>Quanto vale</b> realizar o sonho de mudar seu corpo?
              </div>
              <div>
                <b>Quanto vale</b> colocar uma roupa e se sentir bem nela?
              </div>
              <div>
                <b>Quanto vale</b> ter disposição durante o dia?
              </div>
              <div>
                <b>Quanto vale</b> ouvir das pessoas que você está melhor?
              </div>
              <div>
                <b>Quanto vale</b> saber que está saudável e poder aproveitar a vida?
              </div>
              <div>
                <b>Quanto vale</b> ter tudo isso sem precisar passar fome ou se privar de ter uma vida social?
              </div>
              <div class="white--text mt-6">
                <p>Não se esqueça dessa frase:</p>
                <h1><big>“Saúde não é gasto, é investimento!”</big></h1>
              </div>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>
<script>
  export default {
    name: 'SectionValueAnchor',
  }
</script>
